import React from "react";

const MethodPayment = React.lazy(() =>
  import("./pages/PaymentMethod/MethodPayment").then((module) => ({ default: module.MethodePayment }))
);

const Transaction = React.lazy(() =>
  import("./pages/Transaction").then((module) => ({ default: module.Transaction }))
);
const TransactionStatus = React.lazy(() =>
  import("./pages/TransactionStatus").then((module) => ({ default: module.TransactionStatus }))
);

export const Routing = [
    {
        title: "Payment Links",
        path: `/payment-links/:transactionToken`,
        component: MethodPayment,
    },
    {
        title: "Transaction",
        path: `/payment-links/:transactionToken/:method/:code`,
        component: Transaction,
    },
    {
        title: "Transaction Status",
        path: "/status/:transactionToken",
        component: TransactionStatus,
    }
]