import { Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { StepsLayout } from './components/Steps/Steps';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
import { ExpiredSession } from './pages/ErrorPage/ExpiredSession';
import { NotFound } from './pages/ErrorPage/NotFound';
import { Routing } from './routing';

function App() {
  const [step, setStep] = useState(0)
  const [error, setError] = useState({errorCode: 0, errorMessage: null})
  return (
    <Suspense fallback={'Loading...'}>
      <BrowserRouter>
        <Routes>
          {Routing?.map((route, i) => {
            const Component = route.component
            return (
              <Route
              key={route.path}
              path={route.path}
              element={
                <div key={route.path} title={route.title} className='.App'>
                  {
                    error?.errorCode === -1 ? 
                    error?.errorMessage === "Transaction Token expired!" ? (
                      <ExpiredSession />
                    ) : (
                      <ErrorPage errorMessage={error?.errorMessage}/>
                    ) :(
                    <>
                      <StepsLayout step={step} setStep={setStep} setError={setError}/>
                      <Component title={route.title} key={route.path} setStep={setStep} step={step} setError={setError} />
                    </>
                    )
                  }
                </div>
              }
            >
            </Route>
            )
          })}
             <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
