import { Col, Row } from "antd"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import './style.css'

export const StepsLayout = ({step, setStep}) => {
    const pathname = window.location.pathname
    const {method, code} = useParams()
    useEffect(() => {
        if(!method && !code){
            setStep(0)
        } else if(!pathname.includes('status')){
            setStep(1)
        }
    }, [pathname])
    return (
    <Row gutter={[20,20]} className="custom-steps-container">
        <Col span={4} className="step">
            <div className="step-number">1</div>
            <div className="step-desc">Choose Method</div>
        </Col>
        <Col span={4} >
            <div className={`line${step>0 ? "":"-inactive"}`}></div>
        </Col>
        <Col span={4} className={`step${step<1 ?"-inactive" :""}`}>
            <div className={`step-number${step<1 ?"-inactive":""}`}>2</div>
            <div className="step-desc">Waiting</div>
        </Col>
        <Col span={4} className="step-between-inactive">
            <div className={`line${step>1 ? "":"-inactive"}`}></div>
        </Col>
        <Col span={4} className={`step${step<2 ?"-inactive":""}`}>
            <div className={`step-number${step<2 ?"-inactive":""}`}>3</div>
            <div className="step-desc">Status</div>
        </Col>
    </Row>
    )
}