import error from "../../assets/error.png"
export const NotFound = () => {
    return(
        <div style={{textAlign: 'center'}}>
            <h1>OOPS!</h1>
            <div>
                <img src={error} alt="error" width={'30%'} />
            </div>
            <h3>Error 404, page not found</h3>
            <p>Please re-check your url </p>
        </div>
    )
}