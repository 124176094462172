import error from "../../assets/error.png"

export const ErrorPage = ({errorMessage}) => {
    return(
        <div style={{textAlign: 'center'}}>
            <h1>OOPS!</h1>
            <div>
                <img src={error} alt="error" width={'30%'} />
            </div>
            <h3>Error</h3>
            <p>{errorMessage}</p>
        </div>
    )
}